import EquipmentNotFoundError from '/src/lib/equipment/EquipmentNotFoundError'

const FirestoreError = {
  PERMISSION_DENIED: 'permission-denied',
  CANCELLED: 'cancelled',
  UNKNOWN: 'unknown',
  INVALID_ARGUMENT: 'invalid-argument',
  DEADLINE_EXCEEDED: 'deadline-exceeded',
  NOT_FOUND: 'not-found',
  ALREADY_EXISTS: 'already-exists',
  RESOURCE_EXHAUSTED: 'resource-exhausted',
  FAILED_PRECONDITION: 'failed-precondition',
  ABORTED: 'aborted',
  OUT_OF_RANGE: 'out-of-range',
  UNIMPLEMENTED: 'unimplemented',
  INTERNAL: 'internal',
  UNAVAILABLE: 'unavailable',
  DATA_LOSS: 'data-loss',
  UNAUTHENTICATED: 'unauthenticated',
}

class EquipmentFirestore {
  constructor(firestore, auth, { emulatorHost, emulatorPort }) {
    this.firestore = firestore
    this.auth = auth

    if (emulatorHost) {
      this.firestore.useEmulator(emulatorHost, emulatorPort)
    }

    this.equipments = this.firestore.collection('equipments')
  }

  getEquipments() {
    return this.equipments
      .where('owner_id', '==', this.auth.currentUser.uid)
      .get()
      .then((query) => {
        const e = []
        query.forEach((doc) => e.push(this._mapEquipment(doc.data())))
        return e
      })
  }

  getEquipment(equipmentId) {
    return this.equipments
      .doc(equipmentId)
      .get()
      .then((doc) => this._mapEquipment(doc.data()))
      .catch((err) => {
        if (err.code === FirestoreError.PERMISSION_DENIED) {
          throw new EquipmentNotFoundError(equipmentId)
        }

        throw err
      })
  }

  setEquipment(equipment) {
    return this.equipments.doc(equipment.id).set(equipment)
  }

  _mapEquipment(equipment) {
    const historyEvents = equipment.history.events.reduce(
      (events, event) => [...events, { ...event, date: event.date.toDate() }],
      []
    )

    return { ...equipment, history: { ...equipment.history, events: historyEvents } }
  }
}

export default EquipmentFirestore
