import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import EventInfo from '/src/components/History/EventInfo'
import EventDate from '/src/components/History/EventDate'
import EventTypeIcon from '/src/components/History/EventTypeIcon'

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  alignItems: 'center',
  padding: 0,
  '&:last-child': { padding: 0 },
}))

const EventCard = ({ icon, event }) => {
  return (
    <Card sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ margin: 3, display: 'flex', alignItems: 'center' }}>
        <EventTypeIcon
          eventType={event.event_type}
          color="text.primary"
          fontSize="large"
          data-test-id="event-icon"
        />
      </Box>

      <StyledCardContent>
        <Box flexGrow={1}>
          <EventInfo event={event} data-test-id="event-info" />
        </Box>

        <Box sx={{ mx: 3 }}>
          <EventDate value={event.date} data-test-id="event-date" />
        </Box>
      </StyledCardContent>
    </Card>
  )
}

export default EventCard
