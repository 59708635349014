import React from 'react'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const mapSize = (size) => {
  const sizes = { small: 20, medium: 30, large: 60 }
  return sizes[size] || size
}

const Loading = ({ inline = false, size = 60 }) => {
  if (inline) {
    return <CircularProgress size={mapSize(size)} />
  }

  return (
    <Backdrop open invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress size={size} />
    </Backdrop>
  )
}

export default Loading
