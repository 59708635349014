import { createContext, useContext, useEffect, useState } from 'react'

import Services from '@services/Services'

import Meru from '@lib/Meru'
import { Equipment } from '@lib/equipment/Equipment'
import { useAuthentication } from '@hooks/auth/Authentication'

interface EquipmentContext {
  equipments: Equipment[]
  selectedEquipment: Equipment | null
  selectEquipment: (id: string) => void
  deselectEquipment: () => void
  registerEquipment: (equipment: {
    name: string
    thumbnail: string
    distance: number
    time: number
  }) => Promise<Equipment>
  isLoading: boolean
}

const EquipmentContext = createContext({} as EquipmentContext)

export const EquipmentProvider = ({ children }) => {
  const equipments = useProviderEquipment()

  return <EquipmentContext.Provider value={equipments}>{children}</EquipmentContext.Provider>
}

const useProviderEquipment = (): EquipmentContext => {
  const { user } = useAuthentication()

  const [equipments, setEquipments] = useState<Equipment[]>([])
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    Services.equipments.all().then((equipments) => {
      setEquipments(equipments)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (selectedEquipment) {
      Meru.setTitle(selectedEquipment.name)
    } else {
      Meru.clearTitle()
    }
  }, [selectedEquipment])

  const selectEquipment = (equipmentId) => {
    Services.equipments.findById(equipmentId).then(setSelectedEquipment)
  }

  const deselectEquipment = () => {
    setSelectedEquipment(null)
  }

  const registerEquipment = (equipment: {
    name: string
    thumbnail: string
    distance: number
    time: number
  }) => {
    return Services.equipments.register({ ...equipment, owner_id: user?.uid })
  }

  return {
    equipments,
    selectedEquipment,
    selectEquipment,
    deselectEquipment,
    registerEquipment,
    isLoading,
  }
}

export const useEquipment = (): EquipmentContext => {
  const context = useContext(EquipmentContext)

  if (context === undefined) {
    throw new Error('useEquipment must be used within an EquipmentProvider')
  }

  return context
}
