import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import ServiceComponentForm from './Form/ServiceComponentForm'
import { Equipment } from '@lib/equipment/Equipment'
import { Component } from '@lib/component/Component'
import ServiceType from '@lib/ServiceType'
import Services from '@services/Services'
import { useEquipment } from '@hooks/equipment/Equipment'

interface ServiceComponentModalProps {
  onClose: () => void
  open: boolean
}

export const ServiceComponentModal = ({ onClose, open }: ServiceComponentModalProps) => {
  const { equipments, selectedEquipment } = useEquipment()

  const handleServiceComponent = ({ equipmentId, componentId, serviceType }) => {
    Services.equipments.checkComponent(equipmentId, componentId, serviceType).then(() => onClose())
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Service a component</DialogTitle>

      <DialogContent>
        <ServiceComponentForm
          id="service-component-form"
          onSubmit={handleServiceComponent}
          availableEquipments={equipments}
          equipmentId={selectedEquipment?.id}
        />
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" type="submit" form="service-component-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ServiceComponentModal
