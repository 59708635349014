import React, { useEffect, useState } from 'react'

import { Controller, useForm, useWatch } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'

import EquipmentSelect from '../../Equipment/Form/EquipmentSelect'
import ThumbnailField from '../../Form/ThumbnailField'
import DistanceField from '../../Form/DistanceField'
import TimeField from '../../Form/TimeField'

const schema = yup.object().shape({
  equipmentId: yup.string().required(),
  name: yup.string().required(),
  thumbnail: yup.string().required().url(),
  distance: yup.number().typeError('distance must be greater than or equal to 0').required().min(0),
  time: yup.number().typeError('time must be greater than or equal to 0').required().min(0),
})

const defaultValues = { equipmentId: '', name: '', thumbnail: '', distance: 0, time: 0 }

const Steps = { EQUIPMENT: 0, COMPONENT: 1 }

const TrackComponentForm = ({ id, onSubmit, availableEquipments = [], equipmentId }) => {
  const [activeStep, setActiveStep] = useState(Steps.EQUIPMENT)

  const { handleSubmit, setValue, control } = useForm({
    defaultValues: { ...defaultValues, equipmentId: equipmentId },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const selectedEquipmentId = useWatch({ name: 'equipmentId', control })

  useEffect(() => {
    if (selectedEquipmentId) {
      const equipment = availableEquipments.find((e) => e.id === selectedEquipmentId)

      setValue('distance', equipment.usage.distance)
      setValue('time', equipment.usage.time)

      setActiveStep(Steps.COMPONENT)
    }
  }, [selectedEquipmentId])

  return (
    <Box
      id={id}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="off"
      sx={{ pt: 1 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Select equipment</StepLabel>
          <StepContent>
            <Controller
              render={({ field, fieldState: { invalid, error } }) => (
                <EquipmentSelect
                  invalid={invalid}
                  error={error?.message}
                  choices={availableEquipments}
                  fullWidth
                  {...field}
                />
              )}
              name="equipmentId"
              control={control}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Select component</StepLabel>
          <StepContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      label="Component name"
                      {...field}
                      error={invalid}
                      helperText={error?.message}
                      fullWidth
                    />
                  )}
                  name="name"
                  control={control}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { invalid, error } }) => (
                    <ThumbnailField {...field} invalid={invalid} error={error?.message} fullWidth />
                  )}
                  name="thumbnail"
                  control={control}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  render={({ field, fieldState: { invalid, error } }) => (
                    <DistanceField {...field} invalid={invalid} error={error?.message} />
                  )}
                  name="distance"
                  control={control}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TimeField {...field} invalid={invalid} error={error?.message} />
                  )}
                  name="time"
                  control={control}
                />
              </Grid>
            </Grid>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  )
}

export default TrackComponentForm
