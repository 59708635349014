import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import EquipmentCard from '/src/components/Equipment/EquipmentCard'
import ComponentList from '/src/components/Component/ComponentList'

const EquipmentOverview = ({ equipment }) => (
  <Grid container direction="column" spacing={6}>
    <Grid item sx={{ marginTop: 1 }}>
      <EquipmentCard
        equipment={equipment}
        height={{ xs: 256, md: 512 }}
        data-test-id="equipment-card"
      />
    </Grid>

    <Grid item>
      <Typography variant="h2" textAlign="center">
        Components
      </Typography>
    </Grid>

    <Grid item>
      <ComponentList components={equipment.components} data-test-id="components" />
    </Grid>
  </Grid>
)

export default EquipmentOverview
