import * as uuid from 'uuid'

import EventType from '/src/lib/history/EventType'
import ServiceType from '/src/lib/ServiceType'

class EquipmentService {
  constructor(store) {
    this.store = store
  }

  all() {
    return this.store.getEquipments()
  }

  findById(equipmentId) {
    return this.store.getEquipment(equipmentId)
  }

  recordUsage(equipmentId, usage) {
    const time = parseFloat(usage.time)
    const distance = parseFloat(usage.distance)

    return this.store
      .getEquipment(equipmentId)
      .then((equipment) => {
        return {
          ...equipment,
          components: this._recordComponentsUsage(equipment.components, { time, distance }),
        }
      })
      .then((equipment) => ({
        ...equipment,
        usage: {
          time: equipment.usage.time + time,
          distance: equipment.usage.distance + distance,
        },
      }))
      .then((equipment) => this.store.setEquipment(equipment))
      .then(() =>
        this._recordEvent(equipmentId, EventType.USAGE_RECORDED, { equipmentId, time, distance })
      )
  }

  trackComponent(equipmentId, newComponent) {
    const component = {
      id: uuid.v4(),
      name: newComponent.name,
      thumbnail: newComponent.thumbnail,
      usage: {
        time: newComponent.time,
        distance: newComponent.distance,
      },
    }

    return this.store
      .getEquipment(equipmentId)
      .then((equipment) => {
        const components = equipment.components || []

        return {
          ...equipment,
          components: [...components, component],
        }
      })
      .then((equipment) => this.store.setEquipment(equipment))
      .then(() =>
        this._recordEvent(equipmentId, EventType.COMPONENT_TRACKED, { equipmentId, ...component })
      )
  }

  checkComponent(equipmentId, componentId, serviceType) {
    return this.store
      .getEquipment(equipmentId)
      .then((equipment) => {
        const component = equipment?.components.find((component) => component.id === componentId)

        let eventType

        switch (serviceType) {
          case ServiceType.CHECK:
            component.checkedAt = new Date()

            eventType = EventType.COMPONENT_CHECKED
            break
          case ServiceType.REPLACEMENT:
            component.replacedAt = new Date()
            component.usage.time = 0
            component.usage.distance = 0

            eventType = EventType.COMPONENT_REPLACED
            break
        }
        return { equipment, eventType }
      })
      .then(({ equipment, eventType }) => {
        return this.store.setEquipment({ ...equipment }).then(() => eventType)
      })
      .then((eventType) =>
        this._recordEvent(equipmentId, eventType, { equipmentId, id: componentId })
      )
  }

  register(newEquipment) {
    const equipment = {
      id: uuid.v4(),
      owner_id: newEquipment.owner_id,
      name: newEquipment.name,
      thumbnail: newEquipment.thumbnail,
      usage: {
        time: newEquipment.time,
        distance: newEquipment.distance,
      },
    }

    return this.store
      .setEquipment({ ...equipment, components: [], history: { events: [] } })
      .then(() => this._recordEvent(equipment.id, EventType.EQUIPMENT_REGISTERED, equipment))
  }

  _recordComponentsUsage(components, usage) {
    return components.reduce((components, component) => {
      return [
        ...components,
        {
          ...component,
          usage: {
            time: component.usage.time + usage.time,
            distance: component.usage.distance + usage.distance,
          },
        },
      ]
    }, [])
  }

  _recordEvent(equipmentId, eventType, payload) {
    return this.store.getEquipment(equipmentId).then((equipment) => {
      return this.store.setEquipment({
        ...equipment,
        history: {
          ...equipment.history,
          events: [
            ...equipment.history.events,
            { id: uuid.v4(), date: new Date(), event_type: eventType, payload },
          ],
        },
      })
    })
  }
}

export default EquipmentService
