import React from 'react'

import Grid from '@mui/material/Grid'

import EventCard from '/src/components/History/EventCard'

const History = ({ history }) => {
  const sortedEvents = history.events.sort((e1, e2) => e2.date - e1.date)

  return (
    <Grid container direction="column" spacing={2}>
      {sortedEvents.map((e) => (
        <Grid key={`event-${e.id}`} item xs={12}>
          <EventCard event={e} data-test-id={`event-${e.id}`} data-test-group-id="event" />
        </Grid>
      ))}
    </Grid>
  )
}

export default History
