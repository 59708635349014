import React, { ReactNode } from 'react'

import Routing from '@root/routing'

import Link from '@components/Layout/Link'
import Logo from '@components/Brand/Logo'

import { styled } from '@mui/material/styles'

interface HeaderProps {
  children?: ReactNode
}

const Header = ({ children }: HeaderProps) => (
  <HeaderWrapper>
    <div>
      <Link to={Routing.app.index()}>
        <Logo />
      </Link>
    </div>

    {children && <div>{children}</div>}
  </HeaderWrapper>
)

export default Header

const HeaderWrapper = styled('div')`
  background-color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
`
