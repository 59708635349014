import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import ComponentCard from '/src/components/Component/ComponentCard'

const ComponentList = ({ components = [] }) => (
  <Grid container spacing={2}>
    {components.map((component) => (
      <Grid item xs={12} sm={12} md={6} key={`component-${component.id}`}>
        <ComponentCard component={component} data-test-group-id="component" />
      </Grid>
    ))}

    {components.length === 0 && (
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ m: 2, p: 0, '&:last-child': { p: 0 } }}>
            <Typography>No components tracked for this equipment...</Typography>
          </CardContent>
        </Card>
      </Grid>
    )}
  </Grid>
)

export default ComponentList
