import React from 'react'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

const DistanceField = React.forwardRef(({ invalid, error, ...props }, ref) => (
  <TextField
    {...props}
    label="Distance"
    type="number"
    InputProps={{
      endAdornment: <InputAdornment position="end">km</InputAdornment>,
    }}
    error={invalid}
    helperText={error}
    ref={ref}
    inputRef={props.ref}
  />
))

export default DistanceField
