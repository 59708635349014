import React from 'react'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

import ReplaceIcon from '/src/components/Icon/ReplaceIcon'
import CheckIcon from '/src/components/Icon/CheckIcon'

import ServiceType from '/src/lib/ServiceType'

const RadioIcon = ({ icon, ...props }) => {
  const Icon = icon
  const sx = {
    fontSize: 72,
  }

  return <Radio icon={<Icon sx={sx} />} checkedIcon={<Icon sx={sx} />} {...props} />
}

const ServiceTypeSelect = React.forwardRef(({ invalid, error, ...props }, ref) => (
  <FormControl error={invalid} fullWidth>
    <RadioGroup {...props} row ref={ref}>
      <FormControlLabel
        value={ServiceType.CHECK}
        control={<RadioIcon icon={CheckIcon} />}
        label="Check & Clean"
        labelPlacement="bottom"
      />
      <FormControlLabel
        value={ServiceType.REPLACEMENT}
        control={<RadioIcon icon={ReplaceIcon} />}
        label="Replacement"
        labelPlacement="bottom"
      />
    </RadioGroup>

    <FormHelperText error={invalid}>{error}</FormHelperText>
  </FormControl>
))

export default ServiceTypeSelect
