import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import Routes from '/src/routing'

class FirebaseAuthentication {
  constructor(auth, { emulatorHost, emulatorPort } = {}) {
    this.auth = auth

    if (emulatorHost) {
      this.auth.useEmulator(`http://${emulatorHost}:${emulatorPort}`)
    }

    this.uiConfig = {
      signInSuccessUrl: Routes.app.index(),
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
      ],
    }

    this.LoginForm = (props) => (
      <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={this.auth} {...props} />
    )
  }

  onStateChanged(callback) {
    return this.auth.onAuthStateChanged((user) => {
      if (!user) {
        callback(user)
      } else {
        const { uid, displayName, email, photoURL } = user

        callback({ uid, name: displayName, email, avatarUrl: photoURL })
      }
    })
  }
}

export default FirebaseAuthentication
