import React from 'react'

import Async from '/src/components/Layout/Async'
import Loading from '/src/components/Layout/Loading'
import ComponentCheckedInfo from '/src/components/History/Events/ComponentCheckedInfo'

import Services from '/src/services/Services'
import { findComponent } from '/src/lib/equipment/Equipment'

const ComponentCheckedInfoContainer = ({ component }) => {
  const SmallLoading = <Loading size="medium" inline />

  const request = () =>
    Services.equipments
      .findById(component.equipmentId)
      .then((equipment) => findComponent(equipment, component.id))
      .then((component) => ({ component }))

  return (
    <Async
      loading={SmallLoading}
      request={request}
      success={ComponentCheckedInfo}
      data-test-id="info"
    />
  )
}

export default ComponentCheckedInfoContainer
