class Enum {
  static keys() {
    return Object.keys(this)
  }

  static values() {
    return Object.values(this)
  }
}

export default Enum
