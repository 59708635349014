import RegisterEquipmentForm from '@components/Equipment/Form/RegisterEquipmentForm'

import { useEquipment } from '@hooks/equipment/Equipment'

interface RegisterEquipmentFormContainerProps {
  id: string
  onSubmit: () => void
}

export const RegisterEquipmentFormContainer = ({
  id,
  onSubmit,
}: RegisterEquipmentFormContainerProps) => {
  const { registerEquipment } = useEquipment()

  const handleSubmit = (equipment) => {
    registerEquipment(equipment).then((e) => onSubmit())
  }

  return <RegisterEquipmentForm id={id} onSubmit={handleSubmit} data-test-id="form" />
}

export default RegisterEquipmentFormContainer
