import React from 'react'

import { Link as RouterLink } from 'react-router-dom'
import MuiLink from '@mui/material/Link'

const Link = ({ children, ...props }) => (
  <MuiLink {...props} component={RouterLink} underline="none">
    {children}
  </MuiLink>
)

export default Link
