import Page from '@components/Layout/Page'
import RegisterEquipmentModal from '@components/Equipment/RegisterEquipmentModal'
import TrackComponentIcon from '@components/Icon/TrackComponentIcon'
import RecordUsageIcon from '@components/Icon/RecordUsageIcon'
import ServiceIcon from '@components/Icon/ServiceIcon'
import { useState } from 'react'
import ServiceComponentModal from '@components/Component/ServiceComponentModal'
import TrackComponentModal from '@components/Component/TrackComponentModal'
import RecordUsageModal from '@components/Equipment/RecordUsageModal'
import { QuickActionType } from '@lib/quickAction/QuickActionType'
import ComponentListContainer from '@containers/component/ComponentListContainer'
import { QuickActionButton } from '@components/quickActions/QuickActionButton'

interface ComponentListTab {}

export const ComponentListTab = ({}: ComponentListTab) => {
  const [currentAction, setCurrentAction] = useState<QuickActionType | null>(null)

  return (
    <div>
      <ComponentListContainer />

      <RegisterEquipmentModal
        open={currentAction == QuickActionType.REGISTER_COMPONENT}
        onClose={() => setCurrentAction(null)}
      />
    </div>
  )
}

export default ComponentListTab
