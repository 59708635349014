import React, { useState, useEffect } from 'react'

import Loading from '/src/components/Layout/Loading'

import NotFoundError from '/src/lib/NotFoundError'

const NotFound = ({ children }) => (
  <div data-test-id="error-not-found">Not Found Error: {children}</div>
)

const UnknownError = ({ children }) => <div data-test-id="error-unknown">Error: {children} </div>

const Async = ({ request, loading, success }) => {
  const [content, setContent] = useState(loading || <Loading data-test-id="loading" />)
  const Success = success

  useEffect(() => {
    const promise = request()

    promise
      .then((result) => setContent(<Success {...result} />))
      .catch((error) => {
        if (error instanceof NotFoundError) {
          setContent(<NotFound>{error.message}</NotFound>)
        } else {
          setContent(<UnknownError>{error.message}</UnknownError>)
        }
      })

    return () => promise.cancel && promise.cancel()
  }, [request])

  return <>{content}</>
}

export default Async
