import { ReactNode } from 'react'

import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

interface PageProps {
  tabs?: ReactNode
  children?: ReactNode
}

export const Page = ({ tabs, children }: PageProps) => (
  <>
    {tabs}

    <StyledPage maxWidth="lg">{children}</StyledPage>
  </>
)

const StyledPage = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  overflow: 'auto',
}))

export default Page
