import React from 'react'

import EquipmentHistory from '/src/components/Equipment/EquipmentHistory'
import { useEquipment } from '/src/hooks/equipment/Equipment'

const EquipmentHistoryContainer = () => {
  const { selectedEquipment } = useEquipment()

  return <EquipmentHistory equipment={selectedEquipment} />
}

export default EquipmentHistoryContainer
