import Enum from '/src/lib/Enum'

class EventType extends Enum {
  static EQUIPMENT_REGISTERED = 'equipment_registered'
  static COMPONENT_TRACKED = 'component_tracked'
  static COMPONENT_REPLACED = 'component_replaced'
  static COMPONENT_CHECKED = 'component_checked'
  static USAGE_RECORDED = 'usage_recorded'
}

export default EventType
