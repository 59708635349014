import React from 'react'

import RegisterEquipmentIcon from '/src/components/Icon/RegisterEquipmentIcon'
import TrackComponentIcon from '/src/components/Icon/TrackComponentIcon'
import RecordUsageIcon from '/src/components/Icon/RecordUsageIcon'
import CheckIcon from '/src/components/Icon/CheckIcon'
import ReplaceIcon from '/src/components/Icon/ReplaceIcon'

import EventType from '/src/lib/history/EventType'

const iconMap = {
  [EventType.EQUIPMENT_REGISTERED]: RegisterEquipmentIcon,
  [EventType.COMPONENT_TRACKED]: TrackComponentIcon,
  [EventType.USAGE_RECORDED]: RecordUsageIcon,
  [EventType.COMPONENT_REPLACED]: ReplaceIcon,
  [EventType.COMPONENT_CHECKED]: CheckIcon,
}

const EventTypeIcon = ({ eventType, ...props }) => {
  const Icon = iconMap[eventType]

  return <Icon {...props} data-test-id="icon" />
}
export default EventTypeIcon
