import { Usage } from '@lib/equipment/Usage'

export class Equipment {
  constructor(
    private _id: string,
    private _name: string,
    private _thumbnailUrl: string,
    private _usage: Usage
  ) {}

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get thumbnailUrl(): string {
    return this._thumbnailUrl
  }

  get usage(): Usage {
    return this._usage
  }
}

export const findComponent = (equipment, componentId) =>
  equipment.components.find((c) => c.id === componentId)
