import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import Services from '@services/Services'

import { useAuthentication } from '@hooks/auth/Authentication'
import { Component } from '@lib/component/Component'
import equipment from '@services/firestore/equipment'

interface ComponentContext {
  components: Component[]
  trackComponent: (component: {
    equipmentId?: string
    name: string
    thumbnail: string
    distance: number
    time: number
  }) => Promise<Component>
  isLoading: boolean
}

const ComponentContext = createContext({} as ComponentContext)

interface ComponentProviderProps {
  children?: ReactNode
}
export const ComponentProvider = ({ children }: ComponentProviderProps) => {
  const context = useProviderComponent()

  return <ComponentContext.Provider value={context}>{children}</ComponentContext.Provider>
}

const useProviderComponent = (): ComponentContext => {
  const { user } = useAuthentication()
  const [components, setComponents] = useState<Component[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const trackComponent = (component: {
    equipmentId?: string
    name: string
    thumbnail: string
    distance: number
    time: number
  }) => {
    return Services.equipments.trackComponent(component.equipmentId, {
      ...component,
      owner_id: user?.uid,
    })
  }

  useEffect(() => {
    Services.equipments
      .all()
      .then((equipments) => equipments.flatMap((equipment) => equipment.components))
      .then((components) => components.filter((c) => c))
      .then((components) => {
        setComponents(components)
        setIsLoading(false)
      })
  }, [])

  return {
    components,
    trackComponent,
    isLoading,
  }
}

export const useComponent = (): ComponentContext => {
  const context = useContext(ComponentContext)

  if (!context) {
    throw new Error('useComponent must be used within an ComponentProvider')
  }

  return context
}
