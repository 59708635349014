import DevConfig from '@services/config/dev'
import TestConfig from '@services/config/test'
import ProdConfig from '@services/config/prod'

// TODO: Use Parcel 2 glob resolver

const config = { development: DevConfig, production: ProdConfig, test: TestConfig }

const configureServices = () => {
  const env = process.env.MERU_ENV || process.env.NODE_ENV || 'development'
  return config[env].configure()
}

const Services = configureServices()

export default Services
