import React from 'react'

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

const ComponentSelect = React.forwardRef(({ choices = [], invalid, error, ...props }, ref) => (
  <TextField
    {...props}
    label="Component"
    select
    error={invalid}
    helperText={error}
    ref={ref}
    inputRef={props.ref}>
    {choices.map((component) => (
      <MenuItem key={`choice-component-${component.id}`} value={component.id}>
        {component.name}
      </MenuItem>
    ))}
  </TextField>
))

export default ComponentSelect
