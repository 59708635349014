import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthentication } from '@hooks/auth/Authentication'
import Loading from '@components/Layout/Loading'
import Routing from '@root/routing'

interface PrivateRouteProps {
  children?: ReactNode
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isLoading, isAuthenticated } = useAuthentication()
  const location = useLocation()

  if (isLoading) {
    return <Loading />
  }

  if (isAuthenticated) {
    return <>{children}</>
  }

  return <Navigate to={Routing.authentication.login()} state={{ from: location }} replace />
}

export default PrivateRoute
