import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { AuthenticationState } from '@lib/auth/AuthenticationState'
import Services from '@services/Services'
import { User } from '@lib/user/User'

interface AuthenticationContext {
  user: User | null
  isAuthenticated: boolean
  isLoading: boolean
  loginForm: ReactNode
}

const useProvideAuthentication = (): AuthenticationContext => {
  const [user, setUser] = useState<User | null>(null)
  const [authState, setAuthState] = useState<AuthenticationState>(AuthenticationState.PENDING)

  useEffect(() => {
    return Services.authentication.onStateChanged((user) => {
      setUser(user)

      !!user
        ? setAuthState(AuthenticationState.AUTHENTICATED)
        : setAuthState(AuthenticationState.UNAUTHENTICATED)
    })
  }, [])

  const isLoading = authState === AuthenticationState.PENDING

  const isAuthenticated = authState === AuthenticationState.AUTHENTICATED

  const LoginForm = Services.authentication.LoginForm

  return {
    user,
    isLoading,
    isAuthenticated,
    loginForm: LoginForm,
  }
}

const AuthenticationContext = createContext({} as AuthenticationContext)

export const AuthenticationProvider = ({ children }) => {
  const auth = useProvideAuthentication()

  return <AuthenticationContext.Provider value={auth}>{children}</AuthenticationContext.Provider>
}

export const useAuthentication = (): AuthenticationContext => {
  const context = useContext(AuthenticationContext)

  if (context === undefined) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider')
  }

  return context
}
