import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import FirebaseAuthentication from '/src/services/firebase/authentication'
import EquipmentFirestore from '/src/services/firestore/equipment'
import EquipmentService from '/src/lib/equipment/EquipmentService'

const configure = (options) => {
  console.log('Configuring production environment')

  const firebaseEmulatorsHost = process.env.FIREBASE_EMULATORS_HOST
  const firebaseAuthPort = process.env.FIREBASE_AUTH_PORT
  const firebaseFirestorePort = process.env.FIREBASE_FIRESTORE_PORT

  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
  }

  const firebaseApp = firebase.initializeApp(firebaseConfig)
  const auth = firebaseApp.auth()
  const firestore = firebaseApp.firestore()

  const equipmentStore = new EquipmentFirestore(firestore, auth, {
    emulatorHost: firebaseEmulatorsHost,
    emulatorPort: firebaseFirestorePort,
  })

  return {
    equipments: new EquipmentService(equipmentStore),
    authentication: new FirebaseAuthentication(auth, {
      emulatorHost: firebaseEmulatorsHost,
      emulatorPort: firebaseAuthPort,
    }),
  }
}

export default { configure }
