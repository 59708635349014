import React, { useState, useEffect } from 'react'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'
interface PageTabsProps {
  value: string
  tabs: string[]
  onChange: (newValue) => void
}

export const PageTabs = ({ value = 'overview', tabs, onChange }: PageTabsProps) => {
  const [tabsHeight, setTabsHeight] = useState(0)

  useEffect(() => {
    setTabsHeight(48)
  }, [])

  return (
    <StyledTabs
      height={tabsHeight}
      value={value}
      onChange={(_, tab) => onChange(tab)}
      variant="fullWidth">
      {tabs.map((tab) => (
        <StyledTab key={tab} label={tab} value={tab} />
      ))}
    </StyledTabs>
  )
}

const StyledTabs = styled(Tabs)<{ height: number }>`
  background-color: ${(props) => props.theme.palette.primary.main};
  height: ${(props) => props.height};

  transition: 'ease-out height 0.2s';
  min-height: 0;

  .MuiTabs-indicator {
    background-color: ${(props) => props.theme.palette.primary.contrastText};
    height: 3px;
  }
`
const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  opacity: 0.4,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightBold,
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
    opacity: 1,
  },
}))

export default PageTabs
