import NotFoundError from '/src/lib/NotFoundError'

class EquipmentNotFoundError extends NotFoundError {
  constructor(equipmentId) {
    super(`Equipment with id ${equipmentId} not found`)

    this.name = 'EquipmentNotFoundError'
    this.equipmentId = equipmentId
  }
}

export default EquipmentNotFoundError
