import RegisterEquipmentModal from '@components/Equipment/RegisterEquipmentModal'
import EquipmentListContainer from '@containers/equipment/EquipmentListContainer'
import TrackComponentIcon from '@components/Icon/TrackComponentIcon'
import RecordUsageIcon from '@components/Icon/RecordUsageIcon'
import ServiceIcon from '@components/Icon/ServiceIcon'
import { useState } from 'react'
import ServiceComponentModal from '@components/Component/ServiceComponentModal'
import TrackComponentModal from '@components/Component/TrackComponentModal'
import RecordUsageModal from '@components/Equipment/RecordUsageModal'
import { RegisterEquipmentButton } from '@components/Equipment/RegisterEquipmentButton'
import { QuickActionType } from '@lib/quickAction/QuickActionType'
import { QuickActionButton } from '@components/quickActions/QuickActionButton'

interface EquipmentsPageProps {}

export const EquipmentListTab = ({}: EquipmentsPageProps) => {
  const [currentAction, setCurrentAction] = useState<QuickActionType | null>(null)

  return (
    <div>
      <RegisterEquipmentButton
        onClick={() => setCurrentAction(QuickActionType.REGISTER_COMPONENT)}
      />

      <EquipmentListContainer />

      <RegisterEquipmentModal
        open={currentAction == QuickActionType.REGISTER_COMPONENT}
        onClose={() => setCurrentAction(null)}
      />
    </div>
  )
}

export default EquipmentListTab
