import EquipmentNotFoundError from '/src/lib/equipment/EquipmentNotFoundError'

class EquipmentStore {
  _getEquipments() {
    return this._simulateDelay((res, err) => {
      res(JSON.parse(localStorage.getItem('equipments')))
    })
  }

  getEquipments() {
    return this._getEquipments().then((equipments) => Object.values(equipments))
  }

  getEquipment(equipmentId) {
    return this._simulateDelay((res, err) => {
      return this._getEquipments()
        .then((equipments) => equipments[equipmentId])
        .then((equipment) =>
          !!equipment ? res(equipment) : err(new EquipmentNotFoundError(equipmentId))
        )
    })
  }

  setEquipment(equipment) {
    return this._simulateDelay((res, err) => {
      this._getEquipments()
        .then((equipments) => this._setEquipments({ ...equipments, [equipment.id]: equipment }))
        .then(() => res())
    })
  }

  _simulateDelay(request) {
    let delay

    return new Promise((res, err) => {
      delay = setTimeout(() => {
        request(res, err)
      }, 100)
    })
  }

  _setEquipments(equipments) {
    return this._simulateDelay((res, err) => {
      localStorage.setItem('equipments', JSON.stringify(equipments))
      res()
    })
  }
}

export default EquipmentStore
