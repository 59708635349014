import React from 'react'

import moment from 'moment'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

const EventDate = ({ value }) => {
  const date = moment(value)

  return (
    <Tooltip title={date.format('MMMM Do, YYYY')} placement="left" arrow>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography variant="h5" sx={{ textTransform: 'uppercase' }} data-test-id="month">
          {date.format('MMM')}
        </Typography>
        <Typography variant="subtitle2" data-test-id="day">
          {date.format('Do')}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default EventDate
