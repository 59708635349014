import React, { useEffect, useState } from 'react'

import { Controller, useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'

import EquipmentSelect from '../../Equipment/Form/EquipmentSelect'
import ComponentSelect from './ComponentSelect'
import ServiceTypeSelect from './ServiceTypeSelect'

import ServiceType from '/src/lib/ServiceType'

const schema = yup.object().shape({
  equipmentId: yup.string().required('equipment is required'),
  componentId: yup.string().required('component is required'),
  serviceType: yup
    .string()
    .required()
    .oneOf(Object.values(ServiceType), 'type of service is required'),
})

const defaultValues = { equipmentId: '', componentId: '', serviceType: '' }

const Steps = { EQUIPMENT: 0, COMPONENT: 1, SERVICE_TYPE: 2 }

const ServiceComponentForm = ({ id, onSubmit, availableEquipments, equipmentId }) => {
  const [selectedEquipment, setSelectedEquipment] = useState()
  const [activeStep, setActiveStep] = useState(Steps.EQUIPMENT)

  const { handleSubmit, control } = useForm({
    defaultValues: { ...defaultValues, equipmentId },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const selectedEquipmentId = useWatch({ name: 'equipmentId', control })
  const selectedComponentId = useWatch({ name: 'componentId', control })

  useEffect(() => {
    if (selectedEquipmentId) {
      const equipment = availableEquipments.find((e) => e.id === selectedEquipmentId)

      setSelectedEquipment(equipment)
      setActiveStep(Steps.COMPONENT)
    }
  }, [selectedEquipmentId])

  useEffect(() => {
    if (selectedComponentId) {
      setActiveStep(Steps.SERVICE_TYPE)
    }
  }, [selectedComponentId])

  return (
    <Box
      id={id}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="off"
      sx={{ pt: 1 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Select equipment</StepLabel>
          <StepContent>
            <Controller
              render={({ field, fieldState: { invalid, error } }) => (
                <EquipmentSelect
                  invalid={invalid}
                  error={error?.message}
                  choices={availableEquipments}
                  fullWidth
                  {...field}
                />
              )}
              name="equipmentId"
              control={control}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Select component</StepLabel>
          <StepContent>
            <Controller
              render={({ field, fieldState: { invalid, error } }) => (
                <ComponentSelect
                  invalid={invalid}
                  error={error?.message}
                  choices={selectedEquipment?.components}
                  fullWidth
                  {...field}
                />
              )}
              name="componentId"
              control={control}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>What service did you perform?</StepLabel>
          <StepContent>
            <Controller
              render={({ field, fieldState: { invalid, error } }) => (
                <ServiceTypeSelect invalid={invalid} error={error?.message} {...field} />
              )}
              name="serviceType"
              control={control}
            />
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  )
}

export default ServiceComponentForm
