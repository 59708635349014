import React from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

import Logo from '@components/Brand/Logo'

import Routing from '@root/routing'

import { useAuthentication } from '@hooks/auth/Authentication'

const Page = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.primary.main,
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  alignItems: 'space-around',
  justifyContent: 'space-around',
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  margin: 'auto',
  padding: theme.spacing(8, 2),
  flexGrow: 1,
  minHeight: 256,
  maxHeight: 720,
  maxWidth: 512,
  display: 'flex',
  alignItems: 'space-around',
  justifyContent: 'space-around',
}))

interface LoginPageProps {}

export const LoginPage = ({}: LoginPageProps) => {
  const { isAuthenticated, loginForm } = useAuthentication()
  const location = useLocation()

  if (isAuthenticated) {
    return <Navigate to={Routing.app.index()} state={{ from: location }} replace />
  }

  return (
    <Box sx={{ backgroundColor: 'primary', height: '100%' }}>
      <Page>
        <StyledPaper sx={{}} elevation={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Logo slogan />
            </Grid>
            <Grid item xs={12}>
              {loginForm}
            </Grid>
          </Grid>
        </StyledPaper>
      </Page>
    </Box>
  )
}

export default LoginPage
