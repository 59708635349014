import Loading from '@components/Layout/Loading'
import { useComponent } from '@hooks/component/Component'
import ComponentList from '@components/Component/ComponentList'

interface ComponentListContainerProps {}

export const ComponentListContainer = ({}: ComponentListContainerProps) => {
  const { components, isLoading } = useComponent()

  if (isLoading) {
    return <Loading />
  }

  return <ComponentList components={components} />
}
export default ComponentListContainer
