import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import TrackComponentForm from './Form/TrackComponentForm'
import { Equipment } from '@lib/equipment/Equipment'
import { Component } from '@lib/component/Component'
import { useEquipment } from '@hooks/equipment/Equipment'
import { useComponent } from '@hooks/component/Component'

interface TrackComponentModalProps {
  onClose: () => void
  open: boolean
}

export const TrackComponentModal = ({ onClose, open }: TrackComponentModalProps) => {
  const { equipments, selectedEquipment } = useEquipment()
  const { trackComponent } = useComponent()

  const handleTrackComponent = (component) => {
    trackComponent(component).then(() => onClose())
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Track component</DialogTitle>

      <DialogContent>
        <TrackComponentForm
          id="track-component-form"
          availableEquipments={equipments}
          equipmentId={selectedEquipment?.id}
          onSubmit={handleTrackComponent}
        />
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" type="submit" form="track-component-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TrackComponentModal
