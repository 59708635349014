import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import RecordUsageForm from '@components/Equipment/Form/RecordUsageForm'
import Services from '@services/Services'
import { useEquipment } from '@hooks/equipment/Equipment'

interface RecordUsageModalProps {
  onClose: () => void
  open: boolean
}

export const RecordUsageModal = ({ open, onClose }: RecordUsageModalProps) => {
  const { equipments, selectedEquipment } = useEquipment()

  const handleRecordUsage = ({ equipmentId, distance, time }) => {
    Services.equipments.recordUsage(equipmentId, { distance, time }).then(() => onClose())
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Record usage</DialogTitle>

      <DialogContent>
        <RecordUsageForm
          id="record-usage-form"
          onSubmit={handleRecordUsage}
          availableEquipments={equipments}
          equipmentId={selectedEquipment?.id}
        />
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" type="submit" form="record-usage-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default RecordUsageModal
