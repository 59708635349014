import { ReactNode } from 'react'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { styled } from '@mui/material/styles'

export interface QuickAction {
  name: string
  icon: ReactNode
  onClick: () => void
}

interface QuickActionButtonProps {
  actions: QuickAction[]
}

export const QuickActionButton = ({ actions = [] }: QuickActionButtonProps) => {
  if (actions.length < 0) {
    return <></>
  }

  return (
    <StyledSpeedDial ariaLabel="quick actions" icon={<SpeedDialIcon />}>
      {actions.reverse().map((quickAction) => (
        <StyledSpeedDialAction
          key={quickAction.name}
          icon={quickAction.icon}
          tooltipTitle={quickAction.name}
          tooltipOpen
          onClick={quickAction.onClick}
        />
      ))}
    </StyledSpeedDial>
  )
}

const StyledSpeedDial = styled(SpeedDial)`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: ${(props) => props.theme.spacing(2)};

  .MuiSpeedDialAction-staticTooltipLabel {
    background-color: ${(props) => props.theme.palette.secondary.main};
    color: ${(props) => props.theme.palette.secondary.contrastText};
    white-space: nowrap;
  }
`

const StyledSpeedDialAction = styled(SpeedDialAction)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.secondary.contrastText};

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.light};
  }
`
