import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    primary: { main: '#6ec4db', contrastText: '#fff' },
    secondary: { main: '#fa4a69', contrastText: '#fff' },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(255, 255, 255, .25)',
  },
  typography: {
    fontFamily: 'Lexend',
    h1: {
      fontFamily: 'Karantina',
    },
    subtitle1: {
      fontWeight: 100,
      textTransform: 'uppercase',
      color: '#fff',
      textAlign: 'center',
      opacity: 0.6,
    },
    subtitle2: {
      fontWeight: 100,
      textTransform: 'uppercase',
      color: 'inherit',
      opacity: 0.6,
    },
    body1: {
      fontSize: 18,
    },
  },
  components: {
    MuiFab: {
      variants: [
        {
          props: { size: 'large' },
          style: { width: 64, height: 64 },
        },
      ],
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme
