import React from 'react'

import EquipmentOverview from '@components/Equipment/EquipmentOverview'
import { useEquipment } from '@hooks/equipment/Equipment'

const EquipmentOverviewContainer = () => {
  const { selectedEquipment } = useEquipment()

  return <EquipmentOverview equipment={selectedEquipment} />
}

export default EquipmentOverviewContainer
