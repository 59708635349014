import React, { useEffect, useState } from 'react'

import { Controller, useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'

import EquipmentSelect from './EquipmentSelect'
import DistanceField from '../../Form/DistanceField'
import TimeField from '../../Form/TimeField'

const schema = yup.object().shape({
  equipmentId: yup.string().required(),
  distance: yup.number().typeError('distance must be a positive number').required().positive(),
  time: yup.number().typeError('time must be a positive number').required().positive(),
})

const defaultValues = { equipmentId: '', distance: 0, time: 0 }

const Steps = { EQUIPMENT: 0, USAGE: 1 }

const RecordUsageForm = ({ id, onSubmit, availableEquipments = [], equipmentId = '' }) => {
  const [activeStep, setActiveStep] = useState(Steps.EQUIPMENT)

  const { handleSubmit, control } = useForm({
    defaultValues: { ...defaultValues, equipmentId },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const selectedEquipmentId = useWatch({ name: 'equipmentId', control })

  useEffect(() => {
    if (selectedEquipmentId) {
      setActiveStep(Steps.USAGE)
    }
  }, [selectedEquipmentId])

  return (
    <Box
      id={id}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="off"
      sx={{ pt: 1 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Select equipment</StepLabel>
          <StepContent>
            <Controller
              render={({ field, fieldState: { invalid, error } }) => (
                <EquipmentSelect
                  invalid={invalid}
                  error={error?.message}
                  choices={availableEquipments}
                  fullWidth
                  {...field}
                />
              )}
              name="equipmentId"
              control={control}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Usage</StepLabel>
          <StepContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  render={({ field, fieldState: { invalid, error } }) => (
                    <DistanceField {...field} invalid={invalid} error={error?.message} />
                  )}
                  name="distance"
                  control={control}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TimeField {...field} invalid={invalid} error={error?.message} />
                  )}
                  name="time"
                  control={control}
                />
              </Grid>
            </Grid>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  )
}

export default RecordUsageForm
