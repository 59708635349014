import Authentication from '/src/services/local/authentication'
import EquipmentService from '/src/lib/equipment/EquipmentService'
import EquipmentLocalStorage from '/src/services/local/equipment_store'

import seeds from '/src/seeds'

const configure = () => {
  console.log('Configuring development environment')

  const equipments = localStorage.getItem('equipments')

  if (equipments === null) {
    localStorage.setItem('equipments', JSON.stringify(seeds.equipments))
  }

  const equipmentStore = new EquipmentLocalStorage()

  return {
    equipments: new EquipmentService(equipmentStore),
    authentication: new Authentication(),
  }
}

export default { configure }
