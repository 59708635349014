import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import RegisterEquipmentForm from '@containers/equipment/Form/RegisterEquipmentFormContainer'

interface RegisterEquipmentModalProps {
  open: boolean
  onClose: () => void
}

export const RegisterEquipmentModal = ({ open, onClose }: RegisterEquipmentModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} data-test-id="modal">
      <DialogTitle>Register equipment</DialogTitle>

      <DialogContent>
        <RegisterEquipmentForm
          onSubmit={onClose}
          id="register-equipment-form"
          data-test-id="form"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" type="submit" form="register-equipment-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RegisterEquipmentModal
