import React, { useEffect, useState } from 'react'

import { useParams, useNavigate, Outlet, useMatch, Navigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import { useEquipment } from '@hooks/equipment/Equipment'

import Loading from '@components/Layout/Loading'
import Page from '@components/Layout/Page'
import EquipmentTabs from '@pages/PageTabs'

import { styled } from '@mui/material/styles'
import Routing from '@root/routing'
import TrackComponentIcon from '@components/Icon/TrackComponentIcon'
import { QuickActionType } from '@lib/quickAction/QuickActionType'
import RecordUsageIcon from '@components/Icon/RecordUsageIcon'
import ServiceIcon from '@components/Icon/ServiceIcon'
import TrackComponentModal from '@components/Component/TrackComponentModal'
import ServiceComponentModal from '@components/Component/ServiceComponentModal'
import RecordUsageModal from '@components/Equipment/RecordUsageModal'
import { QuickActionButton } from '@components/quickActions/QuickActionButton'

const EquipmentPage = () => {
  const navigate = useNavigate()
  const match = useMatch(Routing.equipments.tabs())

  const { equipmentId } = useParams()
  const { selectedEquipment, selectEquipment, deselectEquipment } = useEquipment()

  const [currentAction, setCurrentAction] = useState<QuickActionType | null>(null)

  if (!equipmentId) {
    return <Navigate to={Routing.app.index()} replace />
  }

  const changeTab = (tab) => {
    navigate(Routing.equipments[tab](equipmentId))
  }

  const currentTab = match?.params['currentTab']

  const tabs = (
    <Tabs>
      <Container>
        <EquipmentTabs
          value={currentTab || 'overview'}
          onChange={changeTab}
          tabs={['overview', 'history']}
        />
      </Container>
    </Tabs>
  )

  const quickActions = [
    {
      name: 'Track Component',
      icon: <TrackComponentIcon />,
      onClick: () => setCurrentAction(QuickActionType.TRACK_COMPONENT),
    },
    {
      name: 'Record Usage',
      icon: <RecordUsageIcon />,
      onClick: () => setCurrentAction(QuickActionType.RECORD_USAGE),
    },
    {
      name: 'Service Component',
      icon: <ServiceIcon />,
      onClick: () => setCurrentAction(QuickActionType.SERVICE_COMPONENT),
    },
  ]

  useEffect(() => {
    selectEquipment(equipmentId)

    return () => {
      deselectEquipment()
    }
  }, [equipmentId])

  if (!selectedEquipment) {
    return <Loading />
  }

  return (
    <Page tabs={tabs}>
      <Outlet />

      <QuickActionButton actions={quickActions} />

      <TrackComponentModal
        open={currentAction == QuickActionType.TRACK_COMPONENT}
        onClose={() => setCurrentAction(null)}
      />
      <ServiceComponentModal
        open={currentAction == QuickActionType.SERVICE_COMPONENT}
        onClose={() => setCurrentAction(null)}
      />
      <RecordUsageModal
        open={currentAction == QuickActionType.RECORD_USAGE}
        onClose={() => setCurrentAction(null)}
      />
    </Page>
  )
}

const Tabs = styled(Box)`
  background-color: ${(props) => props.theme.palette.primary.main};
`

export default EquipmentPage
