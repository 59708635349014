const app = {
  index: () => '/',
}

const equipments = {
  index: () => '/equipments',
  show: (id?: string) => (id ? `/equipments/${id}` : `/equipments/:equipmentId`),
  overview: (id?: string) =>
    id ? `/equipments/${id}/overview` : `/equipments/:equipmentId/overview`,
  history: (id?: string) => (id ? `/equipments/${id}/history` : `/equipments/:equipmentId/history`),
  tabs: () => '/equipments/:equipmentId/:currentTab',
}

const components = {
  index: () => '/components',
}

const authentication = {
  login: () => '/login',
}

export default { app, equipments, components, authentication }
