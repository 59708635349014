import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import EquipmentCard from '/src/components/Equipment/EquipmentCard'
import History from '/src/components/History/History'

const EquipmentHistory = ({ equipment }) => (
  <Grid container direction="column" spacing={6}>
    <Grid item sx={{ marginTop: 1 }}>
      <EquipmentCard
        equipment={equipment}
        height={{ xs: 256, md: 512 }}
        data-test-id="equipment-card"
      />
    </Grid>

    <Grid item>
      <Typography variant="h2" textAlign="center">
        History
      </Typography>
    </Grid>

    <Grid item>
      <History history={equipment.history} data-test-id="history" />
    </Grid>
  </Grid>
)

export default EquipmentHistory
