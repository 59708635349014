class Authentication {
  constructor() {
    this.LoginForm = () => <div>fake-login-form</div>
  }

  onStateChanged(callback) {
    const timeout = setTimeout(
      () =>
        callback({
          uid: 'fake-user',
          name: 'Fake User',
          email: 'fake@user.com',
          avatarUrl: '',
        }),
      100
    )
    return () => clearTimeout(timeout)
  }
}

export default Authentication
