import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import Usage from '/src/components/Equipment/Usage'

const MuiCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0, 2),
  '&:last-child': { padding: theme.spacing(0, 2) },
}))

const ComponentCard = ({ component }) => (
  <Card>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <CardMedia
        component="img"
        src={component.thumbnail}
        sx={{ width: 96, height: 96 }}
        data-test-id="thumbnail"
      />

      <MuiCardContent>
        <Grid container columnSpacing={2} alignItems="center" flexWrap="nowrap">
          <Grid item flexGrow={1} flexBasis="min-content">
            <Typography variant="body" data-test-id="name">
              {component.name}
            </Typography>
          </Grid>

          <Grid item>
            <Usage usage={component.usage} data-test-id="usage" />
          </Grid>
        </Grid>
      </MuiCardContent>
    </Box>
  </Card>
)

export default ComponentCard
