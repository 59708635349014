import React from 'react'

import Grid from '@mui/material/Grid'

import EquipmentCard from '@components/Equipment/EquipmentCard'
import { Equipment } from '@lib/equipment/Equipment'

interface EquipmentListProps {
  equipments: Equipment[]
}

const EquipmentList = ({ equipments = [] }: EquipmentListProps) => (
  <Grid container spacing={{ xs: 2, sm: 4 }} justifyContent="center">
    {equipments.map((equipment) => (
      <Grid item xs={6} sm={4} md={2} zeroMinWidth key={`equipment-${equipment.id}`}>
        <EquipmentCard
          equipment={equipment}
          link
          data-test-id={`equipment-${equipment.id}`}
          data-test-group-id="equipment"
        />
      </Grid>
    ))}
  </Grid>
)

export default EquipmentList
