import React from 'react'

import Routes from '@root/routing'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'

import Link from '@components/Layout/Link'
import Usage from '@components/Equipment/Usage'
import { styled } from '@mui/material/styles'

interface EquipmentCardProps {
  equipment: any
  height?: { xs?: number; sm?: number; md?: number; lg?: number }
  link?: boolean
}

export const EquipmentCard = ({ equipment, height, link }: EquipmentCardProps) => {
  const wrapActionArea = (content) => (
    <CardActionArea>
      <Link to={Routes.equipments.show(equipment.id)}>{content}</Link>
    </CardActionArea>
  )

  const renderContent = (content) => {
    if (link) {
      return wrapActionArea(content)
    }

    return content
  }

  return (
    <Card>
      {renderContent(
        <>
          <CardMedia
            component="img"
            src={equipment.thumbnail}
            sx={{ height }}
            data-test-id="thumbnail"></CardMedia>

          <CardContent>
            <EquipmentName color="text.primary" variant="h5">
              {equipment.name}
            </EquipmentName>
          </CardContent>

          <Box sx={{ width: '100%' }}>
            <Usage usage={equipment.usage} contained data-test-id="usage" />
          </Box>
        </>
      )}
    </Card>
  )
}

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`

const EquipmentName = styled(Typography)`
  text-align: center;
  margin: ${(props) => props.theme.spacing(2, 1)};
`

export default EquipmentCard
