import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Usage from '/src/components/Equipment/Usage'

const UsageRecordedInfo = ({ usage }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Typography component="span" variant="body1">
      Recorded
    </Typography>
    <Usage usage={usage} inline />
  </Box>
)

export default UsageRecordedInfo
