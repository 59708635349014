import { useEquipment } from '@hooks/equipment/Equipment'
import Loading from '@components/Layout/Loading'
import EquipmentList from '@components/Equipment/EquipmentList'

const EquipmentListContainer = () => {
  const { equipments, isLoading } = useEquipment()

  if (isLoading) {
    return <Loading />
  }

  return <EquipmentList equipments={equipments} />
}
export default EquipmentListContainer
