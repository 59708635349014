import React from 'react'

import EquipmentRegisteredInfo from '/src/components/History/Events/EquipmentRegisteredInfo'
import UsageRecordedInfo from '/src/components/History/Events/UsageRecordedInfo'
import ComponentTrackedInfo from '/src/components/History/Events/ComponentTrackedInfo'
import ComponentCheckedInfo from '/src/containers/History/Events/ComponentCheckedInfoContainer'
import ComponentReplacedInfo from '/src/containers/History/Events/ComponentReplacedInfoContainer'

import EventType from '/src/lib/history/EventType'

const EventInfo = ({ event }) => {
  switch (event.event_type) {
    case EventType.EQUIPMENT_REGISTERED:
      return (
        <EquipmentRegisteredInfo
          equipment={event.payload}
          data-test-id="equipment-registered-info"
        />
      )
    case EventType.USAGE_RECORDED:
      return <UsageRecordedInfo usage={event.payload} data-test-id="usage-info" />
    case EventType.COMPONENT_TRACKED:
      return (
        <ComponentTrackedInfo component={event.payload} data-test-id="component-tracked-info" />
      )
    case EventType.COMPONENT_CHECKED:
      return (
        <ComponentCheckedInfo component={event.payload} data-test-id="component-checked-info" />
      )
    case EventType.COMPONENT_REPLACED:
      return (
        <ComponentReplacedInfo component={event.payload} data-test-id="component-replaced-info" />
      )
  }
}
export default EventInfo
