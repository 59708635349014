import Button from '@mui/material/Button'

interface RegisterEquipmentButtonProps {
  onClick: () => void
}

export const RegisterEquipmentButton = ({ onClick }: RegisterEquipmentButtonProps) => (
  <Button
    onClick={onClick}
    variant="contained"
    color="secondary"
    size="large"
    fullWidth
    sx={{ padding: 2, mb: 3 }}>
    Register equipment
  </Button>
)
