import React from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Usage } from '@lib/equipment/Usage'

interface UsageItemProps {
  label: string
  value: string | number
  contained: boolean
  inline: boolean
}

const UsageItem = ({ label, value, contained, inline }: UsageItemProps) => {
  const variant = inline ? 'body1' : 'h5'

  return (
    <ItemWrapper contained={contained} inline={inline}>
      <Typography variant={variant}>{value}</Typography>
      <Typography variant="subtitle2">{label}</Typography>
    </ItemWrapper>
  )
}

interface UsageProps {
  usage: Usage
  contained?: boolean
  inline?: boolean
}

export default function ({ usage, contained = false, inline = false }: UsageProps) {
  const distanceLabel = inline ? 'h' : 'hours'

  return (
    <UsageWrapper contained={contained} inline={inline}>
      <UsageItem label="km" value={usage.distance} contained={contained} inline={inline} />

      {!inline && <Divider orientation="vertical" flexItem />}

      <UsageItem label={distanceLabel} value={usage.time} contained={contained} inline={inline} />
    </UsageWrapper>
  )
}

const ItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop != 'contained' && prop != 'inline',
})<{ contained: boolean; inline: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
  flex-grow: ${(props) => (props.contained ? 1 : 'auto')};
  align-items: center;
  text-align: center;
  margin: ${(props) => props.theme.spacing(props.contained ? 0 : props.inline ? 0.5 : 1)};
  color: ${(props) => (props.contained ? props.theme.palette.secondary.contrastText : 'inherit')};

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

const UsageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop != 'contained' && prop != 'inline',
})<{ contained: boolean; inline: boolean }>`
  display: ${(props) => (props.contained ? 'flex' : 'inline-flex')};
  flex-direction: row;
  justify-content: ${(props) => (props.contained ? 'space-around' : 'flex-start')};

  margin-left: ${(props) => (props.inline ? props.theme.spacing(1) : 0)};
  padding: ${(props) => (props.inline ? props.theme.spacing(0) : props.theme.spacing(2))};

  background-color: ${(props) =>
    props.contained ? props.theme.palette.secondary.light : 'transparent'};
`
