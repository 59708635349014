import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

interface LogoProps {
  slogan?: boolean
  color?: string
}

export const Logo = ({ slogan, color }: LogoProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Title color={color} variant="h1">
      Meru
    </Title>
    {slogan && <Slogan variant="subtitle1">Ride with confidence</Slogan>}
  </Box>
)

const Title = styled(Typography)<{ color?: string }>`
  color: ${(props) => props.color || props.theme.palette.primary.contrastText};

  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  letter-spacing: 0.25rem;
`

const Slogan = styled(Typography)`
  color: transparent;
  opacity: 0.5;

  text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.2), 0 0 0 rgba(0, 0, 0, 0.6),
    1px 1px 1px rgba(255, 255, 255, 0.4);
`

export default Logo
