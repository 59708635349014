import { useNavigate, Outlet, useLocation } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import Page from '@components/Layout/Page'
import EquipmentTabs from '@pages/PageTabs'

import { styled } from '@mui/material/styles'
import Routing from '@root/routing'

const EquipmentPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const changeTab = (tab) => {
    navigate(Routing[tab].index())
  }

  const currentTab = location.pathname.split('/').filter((e) => !!e)[0]

  const tabs = (
    <Tabs>
      <Container>
        <EquipmentTabs
          value={currentTab || 'equipments'}
          onChange={changeTab}
          tabs={['equipments', 'components']}
        />
      </Container>
    </Tabs>
  )

  return (
    <Page tabs={tabs}>
      <Outlet />
    </Page>
  )
}

const Tabs = styled(Box)`
  background-color: ${(props) => props.theme.palette.primary.main};
`

export default EquipmentPage
