const equipments = {
  123: {
    id: '123',
    name: 'Stumpy Comp Carbon',
    thumbnail:
      'https://tradalaxy-production.s3.us-east-2.amazonaws.com/files/55CEbATNzfHz452qkcjh9LjYoQfYNmcDOhc7tge2.jpeg',
    usage: { distance: 210, time: 55.25 },
    components: [
      {
        id: '321',
        name: 'Front Tire',
        usage: { distance: 55, time: 12.75 },
        thumbnail:
          'https://images.internetstores.de/products/615634/02/4ae837/Maxxis_Minion_DHF_27_5__Skinwall_TR_EXO_3C_MaxxTerra_faltbar[1920x1920].jpg?forceSize=false&forceAspectRatio=true&useTrim=true',
      },
      {
        id: '654',
        name: 'Rear Tire',
        usage: { distance: 55, time: 12.75 },
        thumbnail:
          'https://www.pumpmybike.eu/image/cache//data/12_Folding_Tyres/IMG_0068-750x750.jpg',
      },
    ],
    history: {
      events: [],
    },
  },
  456: {
    id: '456',
    name: 'Intense Tracer 279',
    thumbnail: 'https://picsum.photos/id/456/256',
    usage: { distance: 99, time: 5.75 },
    history: {
      events: [],
    },
  },
  789: {
    id: '789',
    name: 'Norco Sight',
    thumbnail: 'https://picsum.photos/id/789/256',
    usage: { distance: 21, time: 8 },
    history: {
      events: [],
    },
  },
  abc: {
    id: 'abc',
    name: 'Specialized Enduro',
    thumbnail: 'https://picsum.photos/id/136/256',
    usage: { distance: 169, time: 15.5 },
    history: {
      events: [],
    },
  },
}

export default { equipments }
