const DEFAULT_TITLE = 'Meru - Ride with Confidence'

function setDocumentTitle(formattedTitle) {
  if (document && formattedTitle && document.title !== formattedTitle) {
    document.title = formattedTitle
  }
}

const setTitle = (title) => {
  setDocumentTitle(`${title} | ${DEFAULT_TITLE}`)
}

const clearTitle = () => {
  setDocumentTitle(DEFAULT_TITLE)
}

export default { setTitle, clearTitle }
