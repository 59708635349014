import React from 'react'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import DistanceField from '/src/components/Form/DistanceField'
import TimeField from '/src/components/Form/TimeField'
import ThumbnailField from '/src/components/Form/ThumbnailField'

const schema = yup.object().shape({
  name: yup.string().required(),
  thumbnail: yup.string().required(),
  distance: yup.number().typeError('distance must be greater than or equal to 0').required().min(0),
  time: yup.number().typeError('time must be greater than or equal to 0').required().min(0),
})

const defaultValues = { name: '', thumbnail: '', distance: 0, time: 0 }

const RegisterEquipmentForm = ({ id, onSubmit }) => {
  const { handleSubmit, control } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  return (
    <Box id={id} onSubmit={handleSubmit(onSubmit)} component="form" sx={{ pt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                label="Name"
                helperText={error?.message}
                error={invalid}
                fullWidth
                {...field}
              />
            )}
            name="name"
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            render={({ field, fieldState: { invalid, error } }) => (
              <ThumbnailField {...field} invalid={invalid} error={error?.message} fullWidth />
            )}
            name="thumbnail"
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { invalid, error } }) => (
              <DistanceField {...field} invalid={invalid} error={error?.message} />
            )}
            name="distance"
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { invalid, error } }) => (
              <TimeField {...field} invalid={invalid} error={error?.message} />
            )}
            name="time"
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default RegisterEquipmentForm
