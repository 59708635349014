import Authentication from '/src/services/local/authentication'
import EquipmentService from '/src/lib/equipment/EquipmentService'
import EquipmentLocalStorage from '/src/services/local/equipment_store'

const configure = () => {
  return {
    equipments: new EquipmentService(new EquipmentLocalStorage()),
    authentication: new Authentication(),
  }
}

export default { configure }
