import React from 'react'

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

const EquipmentSelect = React.forwardRef(({ choices = [], invalid, error, ...props }, ref) => (
  <TextField
    {...props}
    label="Equipment"
    select
    error={invalid}
    helperText={error}
    ref={ref}
    inputRef={props.ref}>
    {choices.map((equipment) => (
      <MenuItem key={`choice-equipment-${equipment.id}`} value={equipment.id}>
        {equipment.name}
      </MenuItem>
    ))}
  </TextField>
))

export default EquipmentSelect
