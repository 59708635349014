import React from 'react'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

const ThumbnailField = React.forwardRef(({ invalid, error, ...props }, ref) => (
  <TextField
    {...props}
    label="Thumbnail URL"
    error={invalid}
    helperText={error}
    ref={ref}
    inputRef={props.ref}
  />
))

export default ThumbnailField
