import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { AuthenticationProvider } from '@hooks/auth/Authentication'
import { EquipmentProvider } from '@hooks/equipment/Equipment'

import { PrivateRoute } from '@components/PrivateRoute'
import Header from '@components/Layout/Header'

import EquipmentListTab from '@pages/EquipmentListTab'
import EquipmentPage from '@pages/EquipmentPage'
import LoginPage from '@pages/LoginPage'

import Routing from '@root/routing'

import EquipmentOverview from '@containers/equipment/EquipmentOverviewContainer'
import EquipmentHistory from '@containers/equipment/EquipmentHistoryContainer'

import theme from '@styles/theme'
import { ComponentProvider } from '@hooks/component/Component'
import HomePage from '@pages/HomePage'
import ComponentListPage from '@pages/ComponentListTab'

const AuthenticatedLayout = () => (
  <PrivateRoute>
    <EquipmentProvider>
      <ComponentProvider>
        <Header />
        <Outlet />
      </ComponentProvider>
    </EquipmentProvider>
  </PrivateRoute>
)

const PublicLayout = () => <Outlet />

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />

    <AuthenticationProvider>
      <BrowserRouter>
        <Routes>
          <Route path={Routing.app.index()} element={<AuthenticatedLayout />}>
            <Route element={<HomePage />}>
              <Route index element={<EquipmentListTab />} />
              <Route path={Routing.equipments.index()} element={<EquipmentListTab />} />
              <Route path={Routing.components.index()} element={<ComponentListPage />} />
            </Route>

            <Route path={Routing.equipments.show()} element={<EquipmentPage />}>
              <Route index element={<EquipmentOverview />} />
              <Route path={Routing.equipments.overview()} element={<EquipmentOverview />} />
              <Route path={Routing.equipments.history()} element={<EquipmentHistory />} />
            </Route>
          </Route>

          <Route path={Routing.authentication.login()} element={<PublicLayout />}>
            <Route index element={<LoginPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthenticationProvider>
  </ThemeProvider>
)

export default App
